import { IOS } from "./appMobile";

export const VERSION_DEFAULT_FILTER = {
  platform: IOS,
  versionCode: undefined,
  status: undefined,
  whiteLabelId: null,
  appType: undefined,
};

export const VERSION_DESCRIPTION_DEFAULT_EN =
  "Update to the latest version for better experience";

export const VERSION_DESCRIPTION_DEFAULT_VI =
  "Cập nhật phiên bản mới nhất với nhiều tiện ích hơn";
