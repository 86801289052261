import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import PermissionDeniedPage from "components/PermissionDenied";
import { MODULE_ADS_SERVICE } from "shared/constants";

const Banner = React.lazy(() => import("containers/ServiceManagement/Banner"));

const BannerRoute = () => {
  return (
    <Route
      path="service"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<Banner />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_ADS_SERVICE]}
          />
        </Suspense>
      }
    />
  );
};

export default BannerRoute;
