import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import PermissionDeniedPage from "components/PermissionDenied";
import { MODULE_WARD } from "shared/constants";

const Ward = React.lazy(() => import("containers/AreaManagement/Ward"));

const WardRoute = () => {
  return (
    <Route
      path="ward"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<Ward />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_WARD]}
          />
        </Suspense>
      }
    />
  );
};

export default WardRoute;
