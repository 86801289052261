import { Route } from "react-router-dom";
import BuildingRoute from "./buildingRoute";
import VersionRoute from "./versionRoute";
import IconRoute from "./iconRoute";
import NotificationRoute from "./notificationRoute";
import WhiteLabelRoute from "./whiteLabelRoute";
import CityRoute from "./cityRoute";
import DistrictRoute from "./district";
import WardRoute from "./ward";
import BannerRoute from "./bannerRoute";
import ServicePartnerRoute from "./servicePartner";
import AdsPartnerRoute from "./adsPartnerRoute";
import PartnerOrderRoute from "./partnerOrderRoute";

function ManagementRoutes() {
  return (
    <Route path="management">
      {BuildingRoute()}
      {IconRoute()}
      {VersionRoute()}
      {WhiteLabelRoute()}
      {NotificationRoute()}
      {CityRoute()}
      {DistrictRoute()}
      {WardRoute()}
      {BannerRoute()}
      {ServicePartnerRoute()}
      {AdsPartnerRoute()}
      {PartnerOrderRoute()}
    </Route >
  );
}

export default ManagementRoutes;
