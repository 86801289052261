import { useContext } from "react";
import { Context as GlobalContext } from "globalContext";
import { getAllCityService } from "services/city";

const useCity = () => {
  const { cities, setCities } = useContext(GlobalContext);

  const getCity = async () => {
    const result = await getAllCityService();
    setCities(result?.data ?? []);
  };

  return { getCity, cities };
};

export default useCity;
