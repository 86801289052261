import { ICityModel } from "./city";
import { ICountryModel } from "./country";

export interface IDistrictResponse {
  id: number;
  prefix: string;
  name: string;
  identifier: string;
  cityId: number;
  description: string;
  city: ICityModel;
  countryId: number;
  country: ICountryModel;
  districtId: number;
  district: IDistrictModel;
}

export interface IDistrictModel {
  id: number;
  prefix: string;
  key: string;
  name: string;
  identifier: string;
  cityId: number;
  description: string;
  city?: ICityModel | null;
  countryId: number;
  country?: ICountryModel | null;
  districtId: number;
  district?: IDistrictModel | null;
}

const DistrictModel: (data?: IDistrictResponse) => IDistrictModel = (data) => ({
  id: data?.id ?? 0,
  prefix: data?.prefix ?? "",
  key: data?.identifier ?? "",
  name: data?.name ?? "",
  identifier: data?.identifier ?? "",
  cityId: data?.cityId ?? 0,
  description: data?.description ?? "",
  city: data?.city ?? null,
  countryId: data?.countryId ?? 0,
  country: data?.country ?? null,
  districtId: data?.districtId ?? 0,
  district: data?.district ?? null,
});

export default DistrictModel;
