import { useContext } from "react";
import { Context as GlobalContext } from "globalContext";
import { getAllVersionService } from "services/version";
import {
  APP_MOBILE_PMS,
  APP_MOBILE_PMS_WHITE_LABEL,
  APP_MOBILE_RESIDENT,
  APP_MOBILE_RESIDENT_WHITE_LABEL,
} from "shared/constants";

const useVersion = () => {
  const {
    versions,
    setVersions,
    versionsOfWhiteLabel,
    setVersionsOfWhiteLabel,
  } = useContext(GlobalContext);

  const getVersion = async () => {
    const result = await getAllVersionService();
    setVersionsOfWhiteLabel(
      result?.data?.filter((version) =>
        [APP_MOBILE_PMS_WHITE_LABEL, APP_MOBILE_RESIDENT_WHITE_LABEL].includes(
          version.appType ?? ""
        )
      ) ?? []
    );
    setVersions(
      result?.data?.filter((version) =>
        [APP_MOBILE_PMS, APP_MOBILE_RESIDENT].includes(version.appType ?? "")
      ) ?? []
    );
  };

  return { getVersion, versions, versionsOfWhiteLabel };
};

export default useVersion;
