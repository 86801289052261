import moment, { Moment } from "moment";
import { IRoleModel } from "./role";

export interface IUserResponse {
  id: number;
  avatar: string;
  birthday: string;
  email: string;
  fullName: string;
  gender: string;
  phone: string;
  roleId: number;
  role: IRoleModel;
  status: string;
}

export interface IUserModel {
  id: number;
  key?: string;
  avatar: string;
  birthday: Moment | undefined;
  email: string | undefined;
  fullName: string | undefined;
  gender: string | undefined;
  phone: string;
  roleId: number | undefined;
  role: IRoleModel | {};
  status?: string;
}

const UserModel: (data?: IUserResponse) => IUserModel = (data) => ({
  id: data?.id ?? 0,
  key: `key-${data?.id ?? 0}`,
  avatar: data?.avatar ?? "",
  birthday: data?.birthday ? moment(data?.birthday) : undefined,
  email: data?.email ?? "",
  fullName: data?.fullName ?? "",
  gender: data?.gender,
  phone: data?.phone ?? "",
  roleId: data?.roleId,
  role: data?.role ?? {},
  status: data?.status ?? "",
});

export default UserModel;
