import { ICityResponse } from "./city";

export interface ICountryResponse {
  id: number;
  name: string;
  identifier: string;
  description?: string;
  cities?: ICityResponse[];
}

export interface ICountryModel {
  id: number;
  key: string;
  name: string;
  identifier: string;
  description?: string;
  cities?: ICityResponse[];
}

const CountryModel: (data?: ICountryResponse) => ICountryModel = (data) => ({
  id: data?.id ?? 0,
  key: data?.identifier ?? "",
  name: data?.name ?? "",
  identifier: data?.identifier ?? "",
  cities: data?.cities ?? [],
  description: data?.description ?? "",
});

export default CountryModel;
