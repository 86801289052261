import * as React from 'react';
import { language } from 'shared/language';
import PermissionDenied from '../PermissionDenied';

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <PermissionDenied
          code="500"
          description={
            language.the_page_you_are_looking_for_might_have_been_removed_had_its_name_changed_or_is_temporarily_unavailable
          }
        />
      );
    }
    return children;
  }
}

export default ErrorBoundary;
