const withCustomComponent = (Component: Object, ...ComponentHoCs: any) => ({
  ...Component,
  ...ComponentHoCs.reduce((result: any, ComponentHoC: any) => {
    const component = {
      ...ComponentHoC(result),
    };
    Object.keys(component).forEach((key) => {
      if (key.indexOf('__emotion_') !== -1) {
        delete component[key];
      }
    });
    return component;
  }, Component),
});

export default withCustomComponent;
