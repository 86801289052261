import { IBuildingModel } from "./building";

export interface IWhiteLabelResponse {
  id: number;
  key: string;
  description: string;
  unitGroups?: IBuildingModel[];
}

export interface IWhiteLabelModel {
  id: number;
  key: string;
  description: string;
  unitGroups?: IBuildingModel[];
}

const WhiteLabelModel: (data?: IWhiteLabelResponse) => IWhiteLabelModel = (
  data
) => ({
  id: data?.id ?? 0,
  key: data?.key ?? "",
  description: data?.description ?? "",
  unitGroups: data?.unitGroups ?? [],
});

export default WhiteLabelModel;
