import convertArrToObj from "../utils/convertArrToObj";

export const CITY_DEFAULT_FILTER = {
  name: undefined,
  country: undefined,
};

export const CITIES_PREFIX = [
  {
    value: "Tỉnh",
    name: "Tỉnh",
  },
  {
    value: "Thành phố",
    name: "Thành phố",
  },
];
export const CITY_BY_ID = convertArrToObj(CITIES_PREFIX, "id");
