import { language } from "shared/language";
import { APP_PROJECT_CONFIG } from "./appProjectConfig";

export const APP_MOBILE_PMS = "APP_PMS";
export const APP_MOBILE_PMS_WHITE_LABEL = "APP_PMS_WHITE_LABEL";
export const APP_MOBILE_RESIDENT = "APP_RESIDENT";
export const APP_MOBILE_RESIDENT_WHITE_LABEL = "APP_RESIDENT_WHITE_LABEL";

export const APP_MOBILE_TYPES = [
  {
    id: APP_MOBILE_RESIDENT,
    name: `${language.app_resident} ${APP_PROJECT_CONFIG.name}`,
    key: APP_MOBILE_RESIDENT,
  },
  {
    id: APP_MOBILE_PMS,
    name: `${language.app_pms} ${APP_PROJECT_CONFIG.name}`,
    key: APP_MOBILE_PMS,
  },
  {
    id: APP_MOBILE_RESIDENT_WHITE_LABEL,
    name: language.app_resident_white_label,
    key: APP_MOBILE_RESIDENT_WHITE_LABEL,
  },
  {
    id: APP_MOBILE_PMS_WHITE_LABEL,
    name: language.app_pms_white_label,
    key: APP_MOBILE_PMS_WHITE_LABEL,
  },
];

export const IOS = "ios";
export const ANDROID = "android";

export const PLATFORMS = [
  { id: IOS, name: language.ios },
  { id: ANDROID, name: language.android },
];

export const REQUIRED_UPDATE_APP = "REQUIRED_UPDATE";
