import { language } from '../language';
import convertArrToObj from '../utils/convertArrToObj';

export const FEMALE = 'FEMALE';
export const MALE = 'MALE';
export const OTHER = 'OTHER';

export const GENDERS = [
  {
    id: MALE,
    name: language.male,
  },
  {
    id: FEMALE,
    name: language.female,
  },
  {
    id: OTHER,
    name: language.other,
  },
];

export const GENDER_BY_ID = convertArrToObj(GENDERS, 'id');
