import { language } from "shared/language";
import { convertArrToObj } from "shared/utils";

export const ICON_DEFAULT_FILTER = {
  key: undefined,
  whiteLabelId: undefined,
  status: undefined,
  nameVi: undefined,
  appType: undefined,
};

export const ICON_STATUS_ACTIVE = "ACTIVE";
export const ICON_STATUS_INACTIVE = "INACTIVE";
export const ICON_STATUS_MAINTENANCE = "MAINTENANCE";

export const ICON_STATUS = [
  {
    id: ICON_STATUS_ACTIVE,
    name: language.status_active,
  },
  {
    id: ICON_STATUS_INACTIVE,
    name: language.status_inactive,
  },
  {
    id: ICON_STATUS_MAINTENANCE,
    name: language.status_maintenance,
  },
];

export const ICON_STATUS_BY_ID = convertArrToObj(ICON_STATUS, "id");
