import { COOKIE_TAG } from '../constants/env';

function save(key = COOKIE_TAG, data = {}) {
  localStorage.setItem(key, JSON.stringify(data));
}

function get(key = COOKIE_TAG) {
  const cache = localStorage.getItem(key);
  return cache ? JSON.parse(cache) : {};
}

const cookie = {
  save,
  get,
};

export default cookie;
