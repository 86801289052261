import { useContext } from "react";
import { Context as GlobalContext } from "globalContext";
import { getProfileService } from "services/account";
import {
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_EXPORT,
  ACTION_GET,
  ACTION_IMPORT,
  ACTION_UPDATE,
} from "shared/constants";

export type THavePermission = {
  isHavePermissionGet: boolean;
  isHavePermissionCreate: boolean;
  isHavePermissionUpdate: boolean;
  isHavePermissionDelete: boolean;
  isHavePermissionImport: boolean;
  isHavePermissionExport: boolean;
};

const usePermission = () => {
  const { authorities, setAuthorities } = useContext(GlobalContext);

  const getAuthorities = async () => {
    const userResult = await getProfileService();
    setAuthorities(userResult?.data?.role?.permissions ?? []);
  };

  function checkViewPermission(modules: string[]): THavePermission {
    const havePermission = {
      isHavePermissionGet: false,
      isHavePermissionCreate: false,
      isHavePermissionUpdate: false,
      isHavePermissionDelete: false,
      isHavePermissionImport: false,
      isHavePermissionExport: false,
    };
    if (!authorities || !authorities.length || !modules.length) {
      return havePermission;
    }
    const authoritiesOfModule = authorities.filter((authority) =>
      modules.includes(authority.module)
    );

    authoritiesOfModule.forEach((authority) => {
      if (authority.action === ACTION_GET) {
        havePermission.isHavePermissionGet = true;
      }
      if (authority.action === ACTION_CREATE) {
        havePermission.isHavePermissionCreate = true;
      }
      if (authority.action === ACTION_UPDATE) {
        havePermission.isHavePermissionUpdate = true;
      }
      if (authority.action === ACTION_DELETE) {
        havePermission.isHavePermissionDelete = true;
      }
      if (authority.action === ACTION_IMPORT) {
        havePermission.isHavePermissionImport = true;
      }
      if (authority.action === ACTION_EXPORT) {
        havePermission.isHavePermissionExport = true;
      }
    });

    return havePermission;
  }

  return { checkViewPermission, getAuthorities, authorities };
};

export default usePermission;
