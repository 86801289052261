import { language } from "../language";
import { Modal } from "antd";
import { PAGINATION_LIMIT } from "../constants/env";

const useConfirmBox = ({
  questionText,
  loading,
  isAll,
  totalItem,
  delayTime = 6,
  onOk,
  onCancel,
}: {
  questionText: string | Element;
  loading?: boolean;
  isAll: boolean;
  totalItem: number;
  delayTime?: number;
  onOk: () => void;
  onCancel: () => void;
}) => {
  const totalItemText = isAll
    ? language.all.toLowerCase()
    : totalItem.toString();

  const question =
    typeof questionText === "object"
      ? questionText
      : questionText?.replace("{item}", totalItemText) ?? "";
  if (delayTime === 0 || (!isAll && totalItem < PAGINATION_LIMIT)) {
    Modal.confirm({
      okText: language.confirm,
      okButtonProps: {
        disabled: loading,
      },
      cancelText: language.cancel,
      title: language.confirm,
      content: question,
      onOk,
      onCancel,
      afterClose: onCancel,
    });
  } else {
    let delay = delayTime - 1;
    const confirmModal = Modal.confirm({
      okText: `(${delay}) ${language.confirm}`,
      okButtonProps: {
        disabled: true || loading,
      },
      cancelText: language.cancel,
      title: language.confirm,
      content: question,
      onOk,
      onCancel,
      afterClose: onCancel,
    });
    const timer = setInterval(() => {
      delay -= 1;
      if (delay === -1) {
        confirmModal.update({
          okText: language.confirm,
          okButtonProps: {
            disabled: false,
          },
        });
      } else {
        confirmModal.update({
          okText: `(${delay}) ${language.confirm}`,
        });
      }
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
    }, delayTime * 1000);
  }
};

export default useConfirmBox;
