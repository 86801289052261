import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_VERSION } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const Version = React.lazy(() => import("containers/AppManagement/Version"));

const VersionRoute = () => {
  return (
    <Route
      path="version"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<Version />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_VERSION]}
          />
        </Suspense>
      }
    />
  );
};

export default VersionRoute;
