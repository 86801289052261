import { language } from "../language";
import convertArrToObj from "../utils/convertArrToObj";

export const ACTION_CREATE = "CREATE";
export const ACTION_UPDATE = "UPDATE";
export const ACTION_GET = "GET";
export const ACTION_DELETE = "DELETE";
export const ACTION_EXPORT = "EXPORT";
export const ACTION_IMPORT = "IMPORT";

export const ACTIONS = [
  {
    id: ACTION_CREATE,
    name: language.create,
  },
  {
    id: ACTION_UPDATE,
    name: language.update,
  },
  {
    id: ACTION_GET,
    name: language.get,
  },
  {
    id: ACTION_DELETE,
    name: language.delete,
  },
  {
    id: ACTION_EXPORT,
    name: language.export,
  },
  {
    id: ACTION_IMPORT,
    name: language.import,
  },
];

export const ACTION_BY_ID = convertArrToObj(ACTIONS, "id");
