import { isSuccessCode } from "shared/utils";
import { useEffect } from "react";
import notification from "components/Notification";
import { language } from "../language";
import { RESPONSE_MAPPING } from "../constants";

const useNotificationResponse = (
  code: number | undefined,
  codeLanguage: string | undefined,
  successConfig?:
    | {
        message: string;
        description: string;
      }
    | undefined
    | null,
  callback?: () => void
) => {
  useEffect(() => {
    if (code) {
      if (isSuccessCode(code)) {
        if (successConfig) {
          notification.success(successConfig);
        }
        callback && callback();
      } else {
        notification.error({
          message: language.error,
          description:
            RESPONSE_MAPPING[codeLanguage || ""] ??
            RESPONSE_MAPPING.SOMETHING_WRONG,
        });
        callback && callback();
      }
    }
  }, [callback, code, codeLanguage, successConfig]);
};

export default useNotificationResponse;
