import React, { Dispatch, RefObject, SetStateAction, useState } from "react";
import PropTypes from "prop-types";
import UserModel, { IUserModel } from "entity/user";
import { IPermissionModel } from "entity/permission";
import { IDistrictModel } from "entity/district";
import { ICityModel } from "entity/city";
import { IWardModel } from "entity/ward";
import { IWhiteLabelModel } from "entity/whiteLabel";
import { IVersionModel } from "entity/version";

type TGlobalContextValue = {
  whiteLabels: IWhiteLabelModel[];
  setWhiteLabels: Dispatch<SetStateAction<IWhiteLabelModel[]>>;
  versions: IVersionModel[];
  setVersions: Dispatch<SetStateAction<IVersionModel[]>>;
  versionsOfWhiteLabel: IVersionModel[];
  setVersionsOfWhiteLabel: Dispatch<SetStateAction<IVersionModel[]>>;
  districts: IDistrictModel[];
  setDistricts: Dispatch<SetStateAction<IDistrictModel[]>>;
  wards: IWardModel[];
  setWards: Dispatch<SetStateAction<IWardModel[]>>;
  cities: ICityModel[];
  setCities: Dispatch<SetStateAction<ICityModel[]>>;
  currentUser: IUserModel;
  setCurrentUser: Dispatch<SetStateAction<IUserModel>>;
  authorities: IPermissionModel[];
  setAuthorities: Dispatch<SetStateAction<IPermissionModel[]>>;
  visibleFilterBox: boolean;
  setVisibleFilterBox: Dispatch<SetStateAction<boolean>>;
  titlePage: string;
  setTitlePage: Dispatch<SetStateAction<string>>;
  visibleSearchBox: boolean;
  setVisibleSearchBox: Dispatch<SetStateAction<boolean>>;
  noticeSearchBox: number;
  setNoticeSearchBox: Dispatch<SetStateAction<number>>;
  layoutScrollRef: RefObject<HTMLElement> | undefined;
};

const Context = React.createContext<TGlobalContextValue>({
  whiteLabels: [],
  setWhiteLabels: () => {},
  versions: [],
  setVersions: () => {},
  versionsOfWhiteLabel: [],
  setVersionsOfWhiteLabel: () => {},
  districts: [],
  setDistricts: () => {},
  wards: [],
  setWards: () => {},
  cities: [],
  setCities: () => {},
  currentUser: UserModel(),
  setCurrentUser: () => {},
  authorities: [],
  setAuthorities: () => {},
  visibleFilterBox: false,
  setVisibleFilterBox: () => {},
  titlePage: "",
  setTitlePage: () => {},
  visibleSearchBox: false,
  setVisibleSearchBox: () => {},
  noticeSearchBox: 0,
  setNoticeSearchBox: () => {},
  layoutScrollRef: React.createRef<HTMLDivElement>(),
});

const layoutScrollRef = React.createRef<HTMLElement>();

type Props = {
  children?: React.ReactNode;
};

const GlobalContext = (props: Props) => {
  const { children } = props;
  const [currentUser, setCurrentUser] = useState<IUserModel>(UserModel());
  const [authorities, setAuthorities] = useState<IPermissionModel[]>([]);
  const [titlePage, setTitlePage] = useState<string>("");
  const [visibleFilterBox, setVisibleFilterBox] = useState<boolean>(false);
  const [visibleSearchBox, setVisibleSearchBox] = useState<boolean>(false);
  const [noticeSearchBox, setNoticeSearchBox] = useState<number>(0);
  const [districts, setDistricts] = useState<IDistrictModel[]>([]);
  const [wards, setWards] = useState<IWardModel[]>([]);
  const [cities, setCities] = useState<ICityModel[]>([]);
  const [whiteLabels, setWhiteLabels] = useState<IWhiteLabelModel[]>([]);
  const [versions, setVersions] = useState<IVersionModel[]>([]);
  const [versionsOfWhiteLabel, setVersionsOfWhiteLabel] = useState<
    IVersionModel[]
  >([]);

  const ctx: TGlobalContextValue = {
    currentUser,
    setCurrentUser,
    authorities,
    setAuthorities,
    visibleFilterBox,
    setVisibleFilterBox,
    titlePage,
    setTitlePage,
    visibleSearchBox,
    setVisibleSearchBox,
    noticeSearchBox,
    setNoticeSearchBox,
    layoutScrollRef,
    districts,
    setDistricts,
    wards,
    setWards,
    cities,
    setCities,
    whiteLabels,
    setWhiteLabels,
    versions,
    setVersions,
    versionsOfWhiteLabel,
    setVersionsOfWhiteLabel,
  };
  return <Context.Provider value={ctx}>{children}</Context.Provider>;
};

export { Context };

GlobalContext.propTypes = {
  children: PropTypes.node,
};

GlobalContext.defaultProps = {
  children: <div />,
};

export default GlobalContext;
