import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_WHITE_LABEL } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const WhiteLabel = React.lazy(
  () => import("containers/ProjectManagement/WhiteLabel")
);

const WhiteLabelRoute = () => {
  return (
    <Route
      path="white-label"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<WhiteLabel />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_WHITE_LABEL]}
          />
        </Suspense>
      }
    />
  );
};

export default WhiteLabelRoute;
