import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_ROLE } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const Role = React.lazy(() => import("containers/ConfigCms/Role"));

function RoleRoute() {
  return (
    <Route
      path="role"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<Role />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_ROLE]}
          />
        </Suspense>
      }
    />
  );
}

export default RoleRoute;
