import logoCyHome from "assets/images/cyhome/logo.png";
import logoCyOffice from "assets/images/cyoffice/logo.png";
import logoBimGroup from "assets/images/bimgroup/logo.png";

import Image from "components/Image";

import backgroundCyHome from "assets/images/cyhome/background.png";
import backgroundCyOffice from "assets/images/cyoffice/background.png";
import backgroundBimGroup from "assets/images/bimgroup/background.png";

export const APP_PROJECT_CYHOME = "CYHOME";
export const APP_PROJECT_CYOFFICE = "CYOFFICE";
export const APP_PROJECT_BIM_GROUP = "BIMGROUP";

const LOGO_PROJECT: any = {
  [APP_PROJECT_CYHOME]: (
    <Image
      src={logoCyHome}
      style={{
        margin: "auto",
        marginTop: "50px",
        marginBottom: "30px",
        width: "200px",
        height: "120px",
        display: "block",
      }}
      alt="Logo"
      preview={false}
    />
  ),
  [APP_PROJECT_CYOFFICE]: (
    <Image
      src={logoCyOffice}
      style={{
        margin: "auto",
        marginTop: "50px",
        marginBottom: "30px",
        width: "200px",
        height: "120px",
        display: "block",
      }}
      alt="Logo"
      preview={false}
    />
  ),
  [APP_PROJECT_BIM_GROUP]: (
    <Image
      src={logoBimGroup}
      style={{
        margin: "auto",
        marginTop: "50px",
        marginBottom: "30px",
        width: "350px",
        height: "70px",
        display: "block",
      }}
      alt="Logo"
      preview={false}
    />
  ),
};

const BACKGROUND_PROJECT: any = {
  [APP_PROJECT_CYHOME]: backgroundCyHome,
  [APP_PROJECT_CYOFFICE]: backgroundCyOffice,
  [APP_PROJECT_BIM_GROUP]: backgroundBimGroup,
};

const NAME_PROJECT: any = {
  [APP_PROJECT_CYHOME]: "CyHome",
  [APP_PROJECT_CYOFFICE]: "CyOffice",
  [APP_PROJECT_BIM_GROUP]: "BIM Group",
};

export const APP_PROJECT_CONFIG = {
  logo:
    LOGO_PROJECT?.[process.env.REACT_APP_PROJECT || APP_PROJECT_CYHOME] ??
    LOGO_PROJECT[APP_PROJECT_CYHOME],
  background:
    BACKGROUND_PROJECT?.[process.env.REACT_APP_PROJECT || APP_PROJECT_CYHOME] ||
    BACKGROUND_PROJECT[APP_PROJECT_CYHOME],
  name:
    NAME_PROJECT?.[process.env.REACT_APP_PROJECT || APP_PROJECT_CYHOME] ||
    NAME_PROJECT[APP_PROJECT_CYHOME],
};
