import { useContext } from "react";
import { Context as GlobalContext } from "globalContext";
import { getListCityService, getListDistrictService } from "services/base";

const useBase = () => {
  const { setCities, setDistricts } = useContext(GlobalContext);

  const getBase = async () => {
    const resultGetListCity = await getListCityService();
    setCities(resultGetListCity.data);

    const resultGetListDistrict = await getListDistrictService();
    setDistricts(resultGetListDistrict.data);
  };

  return { getBase };
};

export default useBase;
