import { useContext } from "react";
import { Context as GlobalContext } from "globalContext";
import { IUserModel } from "entity/user";
import { OTHER } from "shared/constants";
import { getProfileService } from "services/account";

const useCurrentUser = () => {
  const { currentUser, setCurrentUser, setAuthorities } =
    useContext(GlobalContext);

  const handleChangeCurrentUser = (user: IUserModel) => {
    setCurrentUser(user);
  };

  const getCurrentUser = async () => {
    const userResult = await getProfileService();

    handleChangeCurrentUser({
      id: userResult?.data?.user.id,
      avatar: userResult?.data?.user.avatar,
      birthday: userResult?.data?.user.birthday,
      email: userResult?.data?.user.email,
      fullName: userResult?.data?.user.fullName,
      gender: userResult?.data?.user.gender ?? OTHER,
      phone: userResult?.data?.user.phone,
      role: userResult?.data?.role,
      roleId: userResult?.data?.user.roleId,
      status: userResult?.data?.user.status,
    });

    setAuthorities(userResult?.data?.role?.permissions ?? []);

    return userResult;
  };

  return { currentUser, handleChangeCurrentUser, getCurrentUser };
};

export default useCurrentUser;
