import { language } from "shared/language";

export const SUPER_ADMIN = "SUPER_ADMIN";

export const USER_ACTIVE = "ACTIVE";
export const USER_INACTIVE = "INACTIVE";

export const USER_STATUS = [
  { id: USER_ACTIVE, name: language.status_active },
  { id: USER_INACTIVE, name: language.status_inactive },
];

export const USER_STATUS_BY_ID = {
  ACTIVE: { id: USER_ACTIVE, name: language.status_active },
  INACTIVE: { id: USER_INACTIVE, name: language.status_inactive },
};

export const USER_CMS_DEFAULT_FILTER = {
  fullName: undefined,
  email: undefined,
  phone: undefined,
};

export const EMPLOYEE = "EMPLOYEE";
export const RESIDENT = "RESIDENT";

export const USER_TYPES = [
  { id: RESIDENT, name: language.resident },
  { id: EMPLOYEE, name: language.employee },
];
