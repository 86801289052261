import React, { ReactElement } from "react";
import { usePermission } from "shared/helpers";

type TProps = {
  children: ReactElement<any, any> | null;
  modules: string[];
  permissionDenied: ReactElement<any, any> | null;
};

const Permission: React.VoidFunctionComponent<TProps> = ({
  children,
  modules,
  permissionDenied,
}) => {
  if (!modules.length) {
    return permissionDenied;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { checkViewPermission } = usePermission();
  return checkViewPermission(modules).isHavePermissionGet
    ? children
    : permissionDenied;
};

export default Permission;
