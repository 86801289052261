import { DEFAULT_COUNTRY_CALLING_CODE } from '../constants/env';

const convertPhoneToNumber = (phone: string | null | undefined, prefixCode = DEFAULT_COUNTRY_CALLING_CODE): string => {
  if (!phone || !prefixCode) {
    return '';
  }
  if (/^0/.test(phone)) {
    return phone.substring(1);
  }
  return phone.substring(prefixCode.length);
};

export default convertPhoneToNumber;
