import {
  APP_PROJECT_BIM_GROUP,
  APP_PROJECT_CYHOME,
  APP_PROJECT_CYOFFICE,
} from "shared/constants";
import themeCyHome from "./theme.cms.cyhome";
import themeCyOffice from "./theme.cms.cyoffice";
import themeBimGroup from "./theme.cms.bimGroup";

const THEME_PROJECT: any = {
  [APP_PROJECT_CYHOME]: themeCyHome,
  [APP_PROJECT_CYOFFICE]: themeCyOffice,
  [APP_PROJECT_BIM_GROUP]: themeBimGroup,
};

export default THEME_PROJECT?.[
  process.env.REACT_APP_PROJECT || APP_PROJECT_CYOFFICE
] ?? THEME_PROJECT[APP_PROJECT_CYHOME];
