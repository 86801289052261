import { TCityFilter } from "containers/AreaManagement/City/FilterBox/filterBox.type";
import CityModel, { ICityModel, ICityResponse } from "entity/city";
import { API_TYPE_CALL_API, METHOD, SERVER } from "shared/constants";
import api, { IError, IResponse } from "./api-config";

const CITY = `${SERVER}/core/cities`;
const CITY_GETS = `${CITY}`;
const CITY_GETS_ALL = `${CITY}/list`;
const CITY_ADD = CITY;
const CITY_REMOVE = (id?: number) => `${CITY}/${id}`;
const CITY_UPDATE = (id?: number) => `${CITY}/${id}`;

type TGetListCityServiceInput = {
  page: number;
  limit: number;
  filter: TCityFilter;
};
interface IGetListCityServiceOutput extends IResponse {
  data: Array<ICityModel>;
}

type TCityServiceInput = {
  countryId: number;
  prefix: string;
  name: string;
  identifier: string;
  description: string;
};

export async function getListCityService({
  filter,
  page,
  limit,
}: TGetListCityServiceInput): Promise<IGetListCityServiceOutput> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CITY_GETS,
      method: METHOD.GET,
      params: {
        page,
        limit,
        ...filter,
      },
    },
  });
  return {
    ...response,
    data: response?.data?.map((data: ICityResponse) => CityModel(data)),
  };
}

export async function addCityService(
  city: TCityServiceInput
): Promise<IResponse | IError> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CITY_ADD,
      method: METHOD.POST,
      params: {},
      body: { ...city },
    },
  });
  return response;
}

export async function deleteCityService(
  idCity?: number
): Promise<IResponse | IError> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CITY_REMOVE(idCity),
      method: METHOD.DELETE,
      params: {},
    },
  });
  return response;
}

export async function updateCityService(
  idCity: number,
  city: TCityServiceInput
): Promise<IResponse | IError> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CITY_UPDATE(idCity),
      method: METHOD.PUT,
      params: {},
      body: { ...city },
    },
  });
  return response;
}

export async function getAllCityService(): Promise<IGetListCityServiceOutput> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CITY_GETS_ALL,
      method: METHOD.GET,
      params: {},
    },
  });

  return {
    ...response,
    data: response?.data?.map((data: ICityResponse) => CityModel(data)),
  };
}
