import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_API } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const ApiConfig = React.lazy(() => import("containers/ConfigCms/Api"));

function ApiRoute() {
  return (
    <Route
      path="api"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<ApiConfig />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_API]}
          />
        </Suspense>
      }
    />
  );
}

export default ApiRoute;
