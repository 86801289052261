import { TWhiteLabelFilter } from "containers/ProjectManagement/WhiteLabel/FilterBox/filterBox.type";
import WhiteLabelModel, {
  IWhiteLabelModel,
  IWhiteLabelResponse,
} from "entity/whiteLabel";
import { API_TYPE_CALL_API, METHOD, SERVER } from "shared/constants";
import api, { IError, IResponse } from "./api-config";

const WHITE_LABEL = `${SERVER}/core/white-labels`;
const WHITE_LABEL_GETS = `${WHITE_LABEL}`;
const WHITE_LABEL_GETS_ALL = `${WHITE_LABEL}/list`;
const WHITE_LABEL_ADD = WHITE_LABEL;
const WHITE_LABEL_REMOVE = (id?: number) => `${WHITE_LABEL}/${id}`;
const WHITE_LABEL_UPDATE = (id?: number) => `${WHITE_LABEL}/${id}`;

interface IGetListWhiteLabelServiceOutput extends IResponse {
  data: Array<IWhiteLabelModel>;
}

type TGetListWhiteLabelServiceInput = {
  page: number;
  limit: number;
  filter: TWhiteLabelFilter;
};

export type TWhiteLabelServiceInput = {
  description: string;
  key: string;
};

export async function getListWhiteLabelService({
  filter,
  page,
  limit,
}: TGetListWhiteLabelServiceInput): Promise<IGetListWhiteLabelServiceOutput> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: WHITE_LABEL_GETS,
      method: METHOD.GET,
      params: {
        page,
        limit,
        ...filter,
      },
    },
  });
  return {
    ...response,
    data: response?.data?.map((data: IWhiteLabelResponse) =>
      WhiteLabelModel(data)
    ),
  };
}

export async function getAllWhiteLabelService(): Promise<IGetListWhiteLabelServiceOutput> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: WHITE_LABEL_GETS_ALL,
      method: METHOD.GET,
      params: {},
    },
  });
  return {
    ...response,
    data: response?.data?.map((data: IWhiteLabelResponse) =>
      WhiteLabelModel(data)
    ),
  };
}

export async function deleteWhiteLabelService(
  idWhiteLabel?: number
): Promise<IResponse | IError> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: WHITE_LABEL_REMOVE(idWhiteLabel),
      method: METHOD.DELETE,
      params: {},
    },
  });
  return response;
}

export async function addWhiteLabelService(
  whiteLabel: TWhiteLabelServiceInput
): Promise<IResponse | IError> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: WHITE_LABEL_ADD,
      method: METHOD.POST,
      params: {},
      body: { ...whiteLabel },
    },
  });
  return response;
}

export async function updateWhiteLabelService(
  idWhiteLabel: number,
  whiteLabel: TWhiteLabelServiceInput
): Promise<IResponse | IError> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: WHITE_LABEL_UPDATE(idWhiteLabel),
      method: METHOD.PUT,
      params: {},
      body: { ...whiteLabel },
    },
  });
  return response;
}
