import { Route } from "react-router-dom";
import ApiRoute from "./apiRoute";
import PermissionRoute from "./permissionRoute";
import RoleRoute from "./roleRoute";
import UserRoute from "./userRoute";

const ConfigRoutes = () => {
  return (
    <Route path="config">
      {PermissionRoute()}
      {ApiRoute()}
      {RoleRoute()}
      {UserRoute()}
    </Route>
  );
};

export default ConfigRoutes;
