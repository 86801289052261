import { convertArrToObj } from "shared/utils";

export const DISTRICT_DEFAULT_FILTER = {
  name: undefined,
  countryId: undefined,
  cityId: undefined,
};

export const DISTRICTS_PREFIX = [
  {
    value: "Quận",
    name: "Quận",
  },
  {
    value: "Huyện",
    name: "Huyện",
  },
  {
    value: "Thị xã",
    name: "Thị xã",
  },
];
export const DISTRICT_BY_ID = convertArrToObj(DISTRICTS_PREFIX, "id");
