import { useContext } from "react";
import { Context as GlobalContext } from "globalContext";
import { getAllWhiteLabelService } from "services/whiteLabel";

const useWhiteLabel = () => {
  const { whiteLabels, setWhiteLabels } = useContext(GlobalContext);

  const getWhiteLabel = async () => {
    const result = await getAllWhiteLabelService();
    setWhiteLabels(result?.data ?? []);
  };

  return { getWhiteLabel, whiteLabels };
};

export default useWhiteLabel;
