import * as BASE from "./base";
import * as API from "./api";
import * as AUTH from "./auth";
import * as ROLE from "./role";
import * as PERMISSION from "./permission";
import * as USER from "./user";
import * as BUILDING from "./user";
import * as VERSION from "./version";
import * as ICON from "./icon";
import * as WHITE_LABEL from "./whiteLabel";
import * as PARTNER from "./partner";

export const RESPONSE_MAPPING: {
  [key: string]: string;
} = {
  ...BASE,
  ...API,
  ...AUTH,
  ...ROLE,
  ...PERMISSION,
  ...USER,
  ...BUILDING,
  ...VERSION,
  ...ICON,
  ...WHITE_LABEL,
  ...PARTNER,
};

export * from "./base";
export * from "./auth";
export * from "./api";
export * from "./role";
export * from "./permission";
export * from "./user";
export * from "./building";
export * from "./icon";
export * from "./version";
export * from "./whiteLabel";
export * from "./partner";
