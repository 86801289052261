import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_ICON } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const Icon = React.lazy(() => import("containers/AppManagement/Icon"));

function IconRoute() {
  return (
    <Route
      path="icon"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<Icon />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_ICON]}
          />
        </Suspense>
      }
    />
  );
}

export default IconRoute;
