import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_UNIT_GROUP } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const Building = React.lazy(
  () => import("containers/ProjectManagement/Building")
);

function BuildingRoute() {
  return (
    <Route
      path="building"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<Building />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_UNIT_GROUP]}
          />
        </Suspense>
      }
    />
  );
}

export default BuildingRoute;
