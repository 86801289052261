import { TVersionFilter } from "containers/AppManagement/Version/FilterBox/filterBox.type";
import VersionModel, { IVersionModel, IVersionResponse } from "entity/version";
import { API_TYPE_CALL_API, METHOD, SERVER } from "shared/constants";
import api, { IError, IResponse } from "./api-config";

const VERSION = `${SERVER}/core/versions`;
const VERSION_GETS = `${VERSION}`;
const VERSION_GETS_ALL = `${VERSION}/list`;
const VERSION_ADD = VERSION;
const VERSION_REMOVE = (id?: number) => `${VERSION}/${id}`;
const VERSION_SET_APP_USE = (id?: number) => `${VERSION}/${id}/app-use`;
const VERSION_SET_REQUIRED_UPDATE = (id?: number) =>
  `${VERSION}/${id}/required-update`;
const VERSION_UPDATE = (id?: number) => `${VERSION}/${id}`;

interface IGetListVersionServiceOutput extends IResponse {
  data: Array<IVersionModel>;
}

type TGetListVersionServiceInput = {
  page: number;
  limit: number;
  filter: TVersionFilter;
};

export type TVersionServiceInput = IVersionModel;

export async function getListVersionService({
  filter,
  page,
  limit,
}: TGetListVersionServiceInput): Promise<IGetListVersionServiceOutput> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VERSION_GETS,
      method: METHOD.GET,
      params: {
        page,
        limit,
        ...filter,
      },
    },
  });
  return {
    ...response,
    data: response?.data?.map((data: IVersionResponse) => VersionModel(data)),
  };
}

export async function getAllVersionService(): Promise<IGetListVersionServiceOutput> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VERSION_GETS_ALL,
      method: METHOD.GET,
      params: {},
    },
  });
  return {
    ...response,
    data: response?.data?.map((data: IVersionResponse) => VersionModel(data)),
  };
}

export async function deleteVersionService(
  idVersion?: number
): Promise<IResponse | IError> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VERSION_REMOVE(idVersion),
      method: METHOD.DELETE,
      params: {},
    },
  });
  return response;
}

export async function setAppUseVersionService(
  idVersion?: number
): Promise<IResponse | IError> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VERSION_SET_APP_USE(idVersion),
      method: METHOD.PATCH,
      params: {},
    },
  });
  return response;
}

export async function setVersionRequiredUpdateService(
  idVersion?: number
): Promise<IResponse | IError> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VERSION_SET_REQUIRED_UPDATE(idVersion),
      method: METHOD.PATCH,
      params: {},
    },
  });
  return response;
}

export async function addVersionService(
  Version: TVersionServiceInput
): Promise<IResponse | IError> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VERSION_ADD,
      method: METHOD.POST,
      params: {},
      body: { ...Version },
    },
  });
  return response;
}

export async function updateVersionService(
  idVersion: number,
  Version: TVersionServiceInput
): Promise<IResponse | IError> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VERSION_UPDATE(idVersion),
      method: METHOD.PUT,
      params: {},
      body: { ...Version },
    },
  });
  return response;
}
