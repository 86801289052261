import { language } from '../language';

export const LANGUAGE_OPTIONS = [
  {
    label: language.vietnamese,
    key: 'vi',
  },
  {
    label: language.english,
    key: 'en',
  },
];
