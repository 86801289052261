import { convertArrToObj } from "shared/utils";

export const WARD_DEFAULT_FILTER = {
  name: undefined,
  countryId: undefined,
  cityId: undefined,
  districtId: undefined,
};

export const WARDS_PREFIX = [
  {
    value: "Xã",
    name: "Xã",
  },
  {
    value: "Phường",
    name: "Phường",
  },

  {
    value: "Thị trấn",
    name: "Thị trấn",
  },
];
export const WARD_BY_ID = convertArrToObj(WARDS_PREFIX, "id");
