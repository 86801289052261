import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import PermissionDeniedPage from "components/PermissionDenied";
import { MODULE_CITY } from "shared/constants";

const City = React.lazy(() => import("containers/AreaManagement/City"));

const CityRoute = () => {
  return (
    <Route
      path="city"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<City />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_CITY]}
          />
        </Suspense>
      }
    />
  );
};

export default CityRoute;
