import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_PERMISSION } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const PermissionConfig = React.lazy(
  () => import("containers/ConfigCms/Permission")
);

function PermissionRoute() {
  return (
    <Route
      path="permission"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<PermissionConfig />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_PERMISSION]}
          />
        </Suspense>
      }
    />
  );
}

export default PermissionRoute;
