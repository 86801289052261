import { language } from "./../language/index";
import convertArrToObj from "../utils/convertArrToObj";

export const BANNER_DEFAULT_FILTER = {
  name: undefined,
  partnerId: undefined,
  buildingId: undefined,
  priority: undefined,
};

export const BANNER_PRIORITY = [
  {
    value: "VIP",
    name: language.vip,
  },
  {
    value: "BASIC",
    name: language.basic,
  },
];
export const PRIORITY_BY_BANNER = convertArrToObj(BANNER_PRIORITY, "id");

export const BANNER_POSITION = [
  {
    value: "HP_BANNER",
    name: language.hp_banner,
  },
  {
    value: "SERVICE_LIST",
    name: language.service_list,
  },

  {
    value: "ANNOUNCEMENT_LIST",
    name: language.announcement_list,
  },
];
export const POSITION_BY_BANNER = convertArrToObj(BANNER_POSITION, "id");
