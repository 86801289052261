import { IIconModel } from "./icon";
import { IWhiteLabelModel } from "./whiteLabel";

export interface IVersionResponse {
  id: number;
  platform: string;
  versionCode: string;
  appType: string;
  status: string;
  isAppUse: boolean;
  descriptionVi: string;
  descriptionEn: string;
  descriptionKo: string;
  descriptionZh: string;
  descriptionJa: string;
  whiteLabelId: number;
  whiteLabel: IWhiteLabelModel;
  icons: IIconModel[];
  isRequiredUpdate: boolean;
  isAddToIcon: boolean;
}

export interface IVersionModel {
  id: number;
  key: string;
  platform: string | null | undefined;
  versionCode: string | null | undefined;
  appType: string | null | undefined;
  status: string | null | undefined;
  isAppUse: boolean | null | undefined;
  descriptionVi: string | null | undefined;
  descriptionEn: string | null | undefined;
  descriptionKo: string | null | undefined;
  descriptionZh: string | null | undefined;
  descriptionJa: string | null | undefined;
  whiteLabelId: number | null | undefined;
  whiteLabel?: IWhiteLabelModel | null | undefined;
  icons: IIconModel[] | null | undefined;
  isRequiredUpdate: boolean;
  isAddToIcon: boolean;
}

const VersionModel: (data?: IVersionResponse) => IVersionModel = (data) => ({
  id: data?.id ?? 0,
  key: `key-${data?.appType}-${data?.id}`,
  platform: data?.platform,
  versionCode: data?.versionCode,
  appType: data?.appType,
  status: data?.status,
  isAppUse: data?.isAppUse ?? false,
  descriptionVi: data?.descriptionVi ?? "",
  descriptionEn: data?.descriptionEn ?? "",
  descriptionKo: data?.descriptionKo ?? "",
  descriptionZh: data?.descriptionZh ?? "",
  descriptionJa: data?.descriptionJa ?? "",
  whiteLabelId: data?.whiteLabelId ?? null,
  whiteLabel: data?.whiteLabel ?? undefined,
  icons: data?.icons ?? [],
  isRequiredUpdate: data?.isRequiredUpdate || false,
  isAddToIcon: data?.isAddToIcon || false,
});

export default VersionModel;
