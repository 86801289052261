import * as React from "react";
import Wrapper from "./Wrapper";
import Spin from "../Spin";
import Spinner from "./Spinner";

type Props = {
  loading: boolean;
  width?: string | number;
  height?: string | number;
  noBackground?: boolean;
  onlyIcon?: boolean;
  size?: "default" | "small" | "large" | undefined;
};

const Loading: React.FC<Props> = (props) => {
  const {
    loading,
    width = "20px",
    height = "20px",
    noBackground = false,
    onlyIcon = false,
    size = "default",
  } = props;
  if (onlyIcon) {
    return loading ? <Spinner width={width} height={height} /> : null;
  }
  return loading ? (
    <Wrapper noBackground={noBackground}>
      <Spin size={size} />
    </Wrapper>
  ) : null;
};

export default Loading;
