import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_PARTNER_ORDER } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const PartnerOrder = React.lazy(
  () => import("containers/ServiceManagement/PartnerOrder")
);

function PartnerOrderRoute() {
  return (
    <Route
      path="partner-order"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<PartnerOrder />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_PARTNER_ORDER]}
          />
        </Suspense>
      }
    />
  );
}

export default PartnerOrderRoute;
