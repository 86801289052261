import { ObjectAnyType } from './../../types/base.type';
/* eslint-disable no-param-reassign */
import createDOMPurify from 'dompurify';
import { isDate, isArray, isObject, isString, isNumber } from 'lodash';
import moment from 'moment';

const DOMPurify = createDOMPurify(window);

const clearXssError = (text: string = ''): string => {
  const textFormat = String(text);
  if (DOMPurify.isSupported) {
    return DOMPurify.sanitize(textFormat);
  }
  return textFormat;
};

const trimData = (text: string = ''): string => {
  const regex = new RegExp(/( {2,})/g);
  return text.trim().replace(regex, ' ');
};

const xssFilter = (value: any, result: any) => {
  if (value === undefined || value === null) {
    return value;
  }
  if (moment.isMoment(value)) {
    return value;
  }
  if (isDate(value)) {
    return value;
  }
  if (isNumber(value)) {
    return value;
  }
  if (isArray(value)) {
    value.forEach((item, index) => {
      if (!result[index]) {
        result[index] = {};
      }
      result[index] = xssFilter(item, result[index]);
    });
    result = Object.keys(result).map((key) => result[key]);
  } else if (isObject(value)) {
    const newValue = value as ObjectAnyType;
    Object.keys(newValue).forEach((key) => {
      if (!result[key]) {
        result[key] = {};
      }
      result[key] = xssFilter(newValue[key], result[key]);
    });
  } else if (isString(value)) {
    result = clearXssError(trimData(value));
  } else {
    return value;
  }
  return result;
};

export default xssFilter;
