import CityModel, { ICityResponse } from "entity/city";
import CountryModel, { ICountryResponse } from "entity/country";
import CountryCodeModel, { ICountryCodeResponse } from "entity/countryCode";
import DistrictModel, { IDistrictResponse } from "entity/district";
import { API_TYPE_CALL_API, METHOD, SERVER } from "shared/constants";
import api from "./api-config";

const SERVER_BASE = `${SERVER}/core/base`;
const COUNTRY_CODE = `${SERVER_BASE}/country-codes`;
const COUNTRY = `${SERVER_BASE}/countries`;
const CITY = `${SERVER_BASE}/cities`;
const DISTRICT = `${SERVER_BASE}/districts`;
const WARD = `${SERVER_BASE}/wards`;
const CHECK_MAINTENANCE = `${SERVER_BASE}/check-maintenance`;

export const getListCountryService = async () => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: COUNTRY,
      method: METHOD.GET,
    },
  });
  return {
    ...response,
    data: response?.data?.map((data: ICountryResponse) => CountryModel(data)),
  };
};

export const getListCountryCodeService = async () => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: COUNTRY_CODE,
      method: METHOD.GET,
    },
  });
  return {
    ...response,
    data: response?.data?.map((data: ICountryCodeResponse) =>
      CountryCodeModel(data)
    ),
  };
};

export const getListCityService = async (countryId?: number) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CITY,
      method: METHOD.GET,
      params: {
        ...(countryId ? { countryId } : {}),
      },
    },
  });
  return {
    ...response,
    data: response?.data?.map((data: ICityResponse) => CityModel(data)),
  };
};

export const getListDistrictService = async (cityId?: number) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: DISTRICT,
      method: METHOD.GET,
      params: {
        ...(cityId ? { cityId } : {}),
      },
    },
  });
  return {
    ...response,
    data: response?.data?.map((data: IDistrictResponse) => DistrictModel(data)),
  };
};

export const getListWardService = async (districtId?: number) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: WARD,
      method: METHOD.GET,
      params: {
        ...(districtId ? { districtId } : {}),
      },
    },
  });
  return {
    ...response,
    data: response?.data?.map((data: IDistrictResponse) => DistrictModel(data)),
  };
};

export const checkMaintenance = async () => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CHECK_MAINTENANCE,
      method: METHOD.GET,
    },
  });
  return response;
};
