import { TDistrictFilter } from "containers/AreaManagement/District/FilterBox/filterBox.type";
import DistrictModel, {
  IDistrictModel,
  IDistrictResponse,
} from "entity/district";
import { API_TYPE_CALL_API, METHOD, SERVER } from "shared/constants";
import api, { IError, IResponse } from "./api-config";

const DISTRICT = `${SERVER}/core/districts`;
const DISTRICT_GETS = `${DISTRICT}`;
const DISTRICT_GETS_ALL = `${DISTRICT}/list`;
const DISTRICT_ADD = DISTRICT;
const DISTRICT_REMOVE = (id?: number) => `${DISTRICT}/${id}`;
const DISTRICT_UPDATE = (id?: number) => `${DISTRICT}/${id}`;

type TGetListDistrictServiceInput = {
  page: number;
  limit: number;
  filter: TDistrictFilter;
};
interface IGetListDistrictServiceOutput extends IResponse {
  data: Array<IDistrictModel>;
}

type TDistrictServiceInput = {
  countryId: number;
  cityId: number;
  prefix: string;
  name: string;
  identifier: string;
  description: string;
};

export async function getListDistrictService({
  filter,
  page,
  limit,
}: TGetListDistrictServiceInput): Promise<IGetListDistrictServiceOutput> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: DISTRICT_GETS,
      method: METHOD.GET,
      params: {
        page,
        limit,
        ...filter,
      },
    },
  });
  return {
    ...response,
    data: response?.data?.map((data: IDistrictResponse) => DistrictModel(data)),
  };
}

export async function addDistrictService(
  district: TDistrictServiceInput
): Promise<IResponse | IError> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: DISTRICT_ADD,
      method: METHOD.POST,
      params: {},
      body: { ...district },
    },
  });
  return response;
}

export async function deleteDistrictService(
  idDistrict?: number
): Promise<IResponse | IError> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: DISTRICT_REMOVE(idDistrict),
      method: METHOD.DELETE,
      params: {},
    },
  });
  return response;
}

export async function updateDistrictService(
  idDistrict: number,
  district: TDistrictServiceInput
): Promise<IResponse | IError> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: DISTRICT_UPDATE(idDistrict),
      method: METHOD.PUT,
      params: {},
      body: { ...district },
    },
  });
  return response;
}

export async function getAllDistrictService(): Promise<IGetListDistrictServiceOutput> {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: DISTRICT_GETS_ALL,
      method: METHOD.GET,
      params: {},
    },
  });

  return {
    ...response,
    data: response?.data?.map((data: IDistrictResponse) => DistrictModel(data)),
  };
}
