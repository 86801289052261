import { ICountryModel } from "./country";
import { IDistrictModel } from "./district";

export interface ICityResponse {
  id: number;
  prefix: string;
  name: string;
  identifier: string;
  countryId: number;
  description: string;
  country: ICountryModel;
  districts: IDistrictModel[];
}

export interface ICityModel {
  id: number;
  prefix: string;
  key: string;
  name: string;
  identifier: string;
  countryId: number;
  description: string;
  country?: ICountryModel | null;
  districts?: IDistrictModel[];
}

const CityModel: (data?: ICityResponse) => ICityModel = (data) => ({
  id: data?.id ?? 0,
  key: data?.identifier ?? "",
  prefix: data?.prefix ?? "",
  name: data?.name ?? "",
  identifier: data?.identifier ?? "",
  countryId: data?.countryId ?? 0,
  districts: data?.districts ?? [],
  description: data?.description ?? "",
  country: data?.country ?? null,
});

export default CityModel;
